/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import logo from './assets/landing-logo.webp';
import twitter from './assets/landing-button-TWITTER.webp';
import telegram from './assets/landing-button-TELEGRAM.webp';
import pump from './assets/landing-button-PUMPFUN.webp';
import dex from './assets/landing-button-DEX.webp';

import c1 from './assets/comic/3.webp';
import c2 from './assets/comic/4.webp';
import c3 from './assets/comic/5.webp';
import c4 from './assets/comic/6.webp';
import c5 from './assets/comic/7.webp';
import c6 from './assets/comic/8.webp';
import c7 from './assets/comic/9.webp';
import c8 from './assets/comic/10.webp';
import c9 from './assets/comic/11.webp';

import { useState, useEffect, useRef } from 'react';

import {
  useAnimate,
  stagger,
  motion,
  useAnimation,
  useInView,
} from 'framer-motion';
import AnimatedDiv from './components/AnimatedDiv';
import AnimatedImage from './components/AnimatedImage';
import AnimatedFooter from './components/AnimatedFooter';

const staggerItems = stagger(0.3, { startDelay: 0.1 });

function useTextAnimation() {
  const [scope, animate] = useAnimate();
  useEffect(() => {
    animate(
      'div',
      { y: [200, 0] },
      {
        type: 'spring',
        damping: 10,
        stiffness: 20,
        ease: 'easeOut',
        bounce: 0.1,
        duration: 0.5,
        delay: staggerItems,
      }
    );
  }, []);
  return scope;
}

function App() {
  const scope = useTextAnimation();
  const scrollRef = useRef(null);

  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { threshold: 1 });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
      setIsVisible(true);
    } else {
      controls.start('hidden');
      setIsVisible(false);
    }
  }, [controls, isInView]);

  const openTwitter = () => {
    window.open('https://x.com/dinocoinonsol', '_blank');
  };
  const openTelegram = () => {
    window.open('https://t.me/+Yi_SEds9N8Q2Y2Vk', '_blank');
  };
  const openPump = () => {
    window.open('https://www.pump.fun/', '_blank');
  };
  const openDex = () => {
    window.open('https://dexscreener.com/', '_blank');
  };

  return (
    <div className="app ">
      <div className="main">
        <div className="navigation">
          <motion.div
            className="button top"
            initial={{ x: -500 }}
            animate={{ x: 0 }}
            transition={{
              type: 'spring',
              damping: 10,
              stiffness: 100,
              ease: 'easeOut',
            }}
            whileHover={{
              scale: 1.2,
              originX: 0.5,
              originY: 0.5,
              originZ: 0.5,
            }}
            whileTap={{ scale: 0.97 }}
            onClick={openTwitter}
          >
            <img src={twitter} alt="twitter" className="nav_button"></img>
          </motion.div>
          <motion.div
            className="button top"
            initial={{ y: -500 }}
            animate={{ y: 0 }}
            transition={{
              type: 'spring',
              damping: 10,
              stiffness: 100,
              ease: 'easeOut',
            }}
            whileHover={{
              scale: 1.2,
              originX: 0.5,
              originY: 0.5,
              originZ: 0.5,
            }}
            whileTap={{ scale: 0.97 }}
            onClick={openTelegram}
          >
            <img src={telegram} alt="telegram" className="nav_button"></img>
          </motion.div>
          <motion.div
            className="button top"
            initial={{ x: 500 }}
            animate={{ x: 0 }}
            transition={{
              type: 'spring',
              damping: 10,
              stiffness: 100,
              ease: 'easeOut',
            }}
            whileHover={{
              scale: 1.2,
              originX: 0.5,
              originY: 0.5,
              originZ: 0.5,
            }}
            whileTap={{ scale: 0.97 }}
            onClick={openDex}
          >
            <img src={dex} alt="pump" className="nav_button"></img>
          </motion.div>
          {/* <motion.div
            className="button top"
            initial={{ x: 500 }}
            animate={{ x: 0 }}
            transition={{
              type: 'spring',
              damping: 10,
              stiffness: 100,
              ease: 'easeOut',
            }}
            whileHover={{
              scale: 1.2,
              originX: 0.5,
              originY: 0.5,
              originZ: 0.5,
            }}
            whileTap={{ scale: 0.97 }}
            onClick={openPump}
          >
            <img src={pump} alt="pump" className="nav_button"></img>
          </motion.div> */}
        </div>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1, duration: 2.5 }}
          transition={{
            type: 'spring',
            damping: 10,
            stiffness: 100,
            ease: 'easeOut',
          }}
        >
          <img src={logo} className="logo"></img>
        </motion.div>
        <motion.div className="text" ref={scope}>
          <motion.div>Harness the power of prehistoric crypto...</motion.div>
          <motion.div className="t1">
            Scroll down to see our backstory!
          </motion.div>
        </motion.div>
      </div>
      {/* <div>
        <img src={comic} className="comic"></img>
      </div> */}
      <div className="comic-container" ref={scrollRef}>
        <AnimatedDiv value={50}>
          <img src={c1} className="comic"></img>
        </AnimatedDiv>
        <div>
          <AnimatedImage src={c2}></AnimatedImage>
        </div>
        <AnimatedDiv value={-50}>
          <img src={c3} className="comic"></img>
        </AnimatedDiv>
        <AnimatedDiv value={50}>
          <img src={c4} className="comic"></img>
        </AnimatedDiv>
        <div>
          <AnimatedImage src={c5}></AnimatedImage>
        </div>
        <AnimatedDiv value={-50}>
          <img src={c6} className="comic"></img>
        </AnimatedDiv>
        <div>
          <AnimatedImage src={c7}></AnimatedImage>
        </div>
        <AnimatedDiv value={50}>
          <img src={c8} className="comic"></img>
        </AnimatedDiv>
        <div>
          <AnimatedImage src={c9}></AnimatedImage>
        </div>
      </div>

      <AnimatedFooter value={50}>
        <div className="join-us">JOIN US</div>
        <div className="navigation">
          <motion.div
            className="button top"
            initial={{ x: -500 }}
            animate={{ x: 0 }}
            transition={{
              type: 'spring',
              damping: 10,
              stiffness: 100,
              ease: 'easeOut',
            }}
            whileHover={{
              scale: 1.2,
              originX: 0.5,
              originY: 0.5,
              originZ: 0.5,
            }}
            whileTap={{ scale: 0.97 }}
            onClick={openTwitter}
          >
            <img src={twitter} alt="twitter" className="nav_button"></img>
          </motion.div>
          <motion.div
            className="button top"
            initial={{ y: -500 }}
            animate={{ y: 0 }}
            transition={{
              type: 'spring',
              damping: 10,
              stiffness: 100,
              ease: 'easeOut',
            }}
            whileHover={{
              scale: 1.2,
              originX: 0.5,
              originY: 0.5,
              originZ: 0.5,
            }}
            whileTap={{ scale: 0.97 }}
            onClick={openTelegram}
          >
            <img src={telegram} alt="telegram" className="nav_button"></img>
          </motion.div>
          <motion.div
            className="button top"
            initial={{ x: 500 }}
            animate={{ x: 0 }}
            transition={{
              type: 'spring',
              damping: 10,
              stiffness: 100,
              ease: 'easeOut',
            }}
            whileHover={{
              scale: 1.2,
              originX: 0.5,
              originY: 0.5,
              originZ: 0.5,
            }}
            whileTap={{ scale: 0.97 }}
            onClick={openDex}
          >
            <img src={dex} alt="pump" className="nav_button"></img>
          </motion.div>
          {/* <motion.div
            className="button top"
            initial={{ x: 500 }}
            animate={{ x: 0 }}
            transition={{
              type: 'spring',
              damping: 10,
              stiffness: 100,
              ease: 'easeOut',
            }}
            whileHover={{
              scale: 1.2,
              originX: 0.5,
              originY: 0.5,
              originZ: 0.5,
            }}
            whileTap={{ scale: 0.97 }}
            onClick={openPump}
          >
            <img src={pump} alt="pump" className="nav_button"></img>
          </motion.div> */}
        </div>
      </AnimatedFooter>

      {/* <div className="dex">
        <div id="dexscreener-embed">
          <iframe
            title="dexscreener"
            src="https://dexscreener.com/solana/DNbjrAM6X2zBbnk9GkhwaE1UM9r5QpadmpGMEmQtsJfK?embed=1&theme=dark"
          ></iframe>
        </div>
      </div> */}
    </div>
  );
}

export default App;
